@font-face {
  font-family: "Open Sans";
  src: url(./OpenSans-VariableFont_wdth\,wght.ttf) format(ttf);
}
* {  box-sizing: border-box;
padding: 0;
margin: 0;
}
body {
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
body > div#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
